import React, { useState, useEffect } from 'react';
import './index.css';
import Modal from '@mui/material/Modal';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default function PaymentModal({ open, onClose, onSubmit, price }) {
  const [errorMsg, setErrorMsg] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setTimeout(() => {
      setErrorMsg(false);
    }, 6000);
  }, [errorMsg]);

  const cardElementOptions = {
    style: {
      base: {
        color: "#ffffff",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "18px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setErrorMsg(error.message);
    } else {
      console.log("PaymentMethod>", paymentMethod);
      onSubmit(paymentMethod);
      onClose(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="conf_modal"
      aria-describedby="modal-modal-description"
    >
      <div
        className="paymentDetails"
        style={{
          width: "50%",
        }}
      >
        <form
          action="#"
          onSubmit={handleSubmit}
          className="card_details"
          style={{
            margin: 0,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div
            className="paymentDetails_heading_wrapper"
            style={{ marginBottom: "20px" }}
          >
            <div
              className="paymentDetails_heading"
              style={{ fontSize: "24px", fontWeight: "bold" }}
            >
              Payment Details
            </div>
            <div className="paymentDetailMsgs" style={{ color: "red" }}>
              {errorMsg && errorMsg}
            </div>
          </div>
          <div
            className="price"
            style={{ fontSize: "18px", fontWeight: "bold" }}
          >
            <div className="price_label">Price: $ {price}</div>
          </div>
          <div className="card_number" style={{ marginBottom: "20px" }}>
            <div className="card_number_header">
              <div
                className="card_label"
                style={{ fontSize: "16px", marginBottom: "10px" }}
              >
                Card Details <br />
                <span style={{ fontSize: "14px", color: "#666" }}>
                  Enter your card information
                </span>
              </div>
            </div>
            <div
              style={{
                padding: "10px",
                backgroundColor: "#1a1a1a",
                borderRadius: "4px",
              }}
            >
              <CardElement options={cardElementOptions} />
            </div>
          </div>
          <div className="confirmation_btn" style={{ textAlign: "center" }}>
            <button
              type="submit"
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Confirmation
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
